
import { defineComponent, ref, inject, onMounted } from 'vue'
export default defineComponent({
  props: {
    activeName: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    const active: any = inject('active')
    const item = ref<null | HTMLElement>(null)
    const currentItemData: any = inject('totalItemsData')

    onMounted(() => {
      if (item.value && currentItemData) {
        if (currentItemData.value[props.activeName]) {
          console.warn(`commonTabsItem activeName "${props.activeName}" repeat`)
        }
        const data = item.value.getBoundingClientRect()
        currentItemData.value[props.activeName] = data
      }
    })

    const choseFn = () => {
      if (active) {
        active.value = props.activeName
      }
    }
    return {
      choseFn,
      active,
      item
    }
  }
})

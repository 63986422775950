
import {
  defineComponent,
  computed,
  ref,
  nextTick,
  watch,
  Ref,
  onMounted,
  PropType
} from 'vue'

type Type = 'check' | 'radio'

type Model = Array<any> | string

export default defineComponent({
  props: {
    modelValue: {
      type: [Array, String, Number],
      default: () => {
        return []
      }
    },
    type: {
      type: String as PropType<Type>,
      default: 'check'
    },
    header: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    keyString: {
      type: String,
      default: ''
    },
    trueString: {
      type: String,
      default: ''
    }
  },
  setup(props, ct) {
    const checkLine: Ref<null | Element> = ref(null)
    const allLabel: Ref<null | Element> = ref(null)
    let baseHeight = 0
    let trueHeight = 0
    const showHeight: Ref<number> = ref(0)
    // 展开全部
    const isToggleShow = ref<boolean>(false)
    const toggleAll = ref<boolean>(false)
    const isIndeterminate = ref<boolean>(false)
    const toggleAllFn = () => {
      toggleAll.value = !toggleAll.value
      showHeight.value = toggleAll.value ? trueHeight : baseHeight
    }
    const initStyle = () => {
      if (!checkLine.value || !allLabel.value) {
        return
      }
      const wrap = checkLine.value.getBoundingClientRect()
      const diffItem = allLabel.value.getBoundingClientRect()
      trueHeight = wrap.height
      baseHeight = diffItem.height
      if (trueHeight < diffItem.height * 2) {
        isToggleShow.value = false
      } else {
        isToggleShow.value = true
        showHeight.value = baseHeight
      }
    }
    watch(
      () => props.list,
      () => {
        nextTick(() => {
          initStyle()
        })
      },
      { deep: true }
    )

    const isAll = ref<boolean>(false)
    const checkList = computed({
      get: () => {
        return props.modelValue
      },
      set: (v) => {
        ct.emit('update:modelValue', v)
      }
    })

    const getAllValue = computed(() => {
      const { list, trueString } = props
      return list.map((item: any) => {
        if (trueString) {
          return item[trueString]
        } else {
          return item
        }
      })
      // return []
    })

    const emitChange = () => {
      nextTick(() => {
        ct.emit('check-change', true)
      })
    }

    const toggleTotal = () => {
      // isAll.value = !isAll.value
      isIndeterminate.value = false
      if (isAll.value) {
        if (props.type === 'check') {
          ct.emit('update:modelValue', getAllValue.value)
        } else {
          ct.emit('update:modelValue', '')
        }
      } else {
        if (props.type === 'check') {
          ct.emit('update:modelValue', [])
        } else {
          ct.emit('update:modelValue', '')
        }
      }
      emitChange()
    }

    const checkBoxChange = () => {
      if (
        typeof checkList.value !== 'number' &&
        checkList.value.length === getAllValue.value.length &&
        checkList.value.length > 0
      ) {
        isAll.value = true
        isIndeterminate.value = false
      } else if (
        typeof checkList.value !== 'number' &&
        checkList.value.length <= 0
      ) {
        isIndeterminate.value = false
        isAll.value = false
      } else {
        isIndeterminate.value = true
        isAll.value = false
      }
      emitChange()
    }

    // 单选
    const choseRadio = (item: any) => {
      let result: string
      if (props.trueString) {
        result = item[props.trueString]
      } else {
        result = item
      }
      isAll.value = false
      ct.emit('update:modelValue', result)
      emitChange()
    }

    onMounted(() => {
      initStyle()
    })

    return {
      checkLine,
      allLabel,
      checkList,
      isAll,
      toggleTotal,
      checkBoxChange,
      toggleAll,
      toggleAllFn,
      showHeight,
      choseRadio,
      isToggleShow,
      isIndeterminate
    }
  }
})

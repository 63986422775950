<template>
  <li class="pointer" @click="toDetail(itemList)">
    <div class="type">
      <img :src="chooseImg(itemList.area_name)" alt="" />
    </div>
    <div class="content">
      <div class="content_title" ref="RefpolicyName">
        {{ itemList.policy_name || itemList.title }}
      </div>
      <div class="tips">
        <div v-show="itemList.org_name" class="tips1">
          {{ itemList.org_name }}
        </div>
        <div v-show="itemList.area_name" class="tips1">
          {{ itemList.area_name }}
        </div>
        <div v-show="itemList.short_name" class="tips2">
          {{ itemList.short_name }}
        </div>
        <template v-if="itemList.lables && itemList.lables.length > 0">
          <div class="tips2" v-for="(i, index) in itemList.lables" :key="index">
            {{ i }}
          </div>
        </template>
      </div>
      <div class="time">
        <img src="~assets/images/common/baseItem/clock.png" alt="" />
        <span v-if="itemList.release_date"
          >{{
            itemList.release_date && itemList.release_date.split('T')[0]
          }}
          -</span
        >
        <span v-if="itemList.expire_date">{{
          itemList.expire_date && itemList.expire_date.split('T')[0]
        }}</span>
        <span v-if="itemList.pubDate">{{ itemList.pubDate }}</span>
      </div>
    </div>
    <div class="money" v-if="Number(itemList.class) !== 2 && Number(itemList.class) !== 3">
      <span v-if="itemList.amount !== 0"
        >{{ itemList.amount }}<span class="unit">万</span></span
      >
      <span v-else style="color: #fa5741; font-size: 14px">以最终审批为准</span>
      <span>最高补助</span>
    </div>
  </li>
</template>

<script>
import chooseImg from '@/page/Detail/components/chooseImg'
export default {
  components: {},
  props: {
    itemList: {
      type: Object,
      default: () => {
        return {
          release_date: '',
          expire_date: ''
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    toDetail({ pid, class: type, uid }) {
      const t = ([2, 3, 4].indexOf(Number(type)) >= 0 ? 'notice' : 'normal')
      this.$emit('toDetail', (pid || uid), t)
    },
    typeChange(code) {
      if (['2001', '2012'].includes(code)) {
        return 1
      } else if (['2003', '2006', '2007'].includes(code)) {
        return 2
      } else if (code === '2008') {
        return 3
      } else if (code === '2009') {
        return 4
      } else if (['2010', '2011'].includes(code)) {
        return 5
      } else {
        return 6
      }
    },
    chooseImg(area) {
      return chooseImg(area)
    }
  }
}
</script>
<style lang="scss" scoped>
li {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  img {
    width: 170px;
    height: 113px;
  }
  .type {
    position: relative;
    width: 180px;
    height: 113px;
    margin-right: 20px;
    overflow: hidden;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: #c8a260;
      white-space: nowrap;
    }
    img {
      width: 100%;
      height: 100%;
      transition: all 1s;
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 64px;
    .content_title {
      max-width: 455px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      color: #000;
      font-size: 18px;
      line-height: 1;
    }
    .tips {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-bottom: 5px;
        padding: 7px 10px;
        background: #f2f5f7;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #849aae;
      }
      > div:last-of-type {
        margin-right: 0;
      }
    }
    .time {
      display: flex;
      align-items: center;
      margin-top: 19px;
      img {
        width: 11px;
        height: 11px;
        margin-right: 2px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #bbbbbb;
      }
    }
  }
  .money {
    align-self: stretch;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > span {
      font-weight: bold;
      color: #fa5741;
      white-space: nowrap;
    }
    > span:first-of-type {
      font-size: 24px;
      margin-bottom: 5px;
    }
    > span:last-child {
      color: #bbbbbb;
      font-size: 12px;
    }
    .unit {
      font-size: 14px;
    }
  }
}
li:last-of-type {
  margin-bottom: 0;
}
li:hover img {
  transform: scale(1.12);
}
li:hover .content_title {
  color: #c8a260;
}
</style>


import { defineComponent, provide, computed, ref, onMounted } from 'vue'

/* eslint-disable */
type TotalDataType = string | number

interface TotalData {
  [key: string]: DOMRect
}

interface LineStyle {
  width: number
  left: number
}

interface DOMRectR extends DOMRect {
  value?: any
}

/* eslint-enable */

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    }
  },
  setup(props, ct) {
    let setLineStyleString: () => string = () => {
      return ''
    }
    const value = computed<TotalDataType>({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
        ct.emit('change', v)
      }
    })
    const lineStyleString = computed(() => {
      return setLineStyleString()
    })
    const lineStyle = ref<LineStyle | undefined>({ width: 0, left: 0 })
    const tabs = ref<null | HTMLElement>(null)
    let tabsData: DOMRectR
    const totalItemsData = ref<TotalData>({})
    setLineStyleString = (): string => {
      const changeItem: DOMRectR = totalItemsData.value[value.value]
      if (!changeItem) {
        return ''
      }
      lineStyle.value = {
        width: changeItem.width,
        left: changeItem.x - tabsData.x
      }

      const lineStyleValue: LineStyle | undefined = lineStyle.value
      if (lineStyleValue) {
        return `;width:${lineStyleValue.width}px;left:${lineStyleValue.left}px;`
      }
      return ''
    }

    provide('totalItemsData', totalItemsData)
    provide('active', value)

    onMounted(() => {
      if (tabs.value) {
        tabsData = tabs.value.getBoundingClientRect()
      }
    })
    return {
      active: '',
      value,
      tabs,
      lineStyle,
      lineStyleString
    }
  }
})

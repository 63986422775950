<template>
  <div id="hotList">
    <policiesTitle title="企业关注热榜" />
    <ul>
      <li
        v-for="(item, index) in hotList"
        v-show="index < 6"
        :key="index"
        class="pointer"
        @click="toDetail(item.pid)"
      >
        <div class="left_part">
          <div
            :class="{
              icon: true,
              first: index === 0,
              second: index === 1,
              third: index === 2
            }"
          >
            {{ index + 1 }}
          </div>
        </div>
        <div class="right_part">
          <div class="part_title">{{ item.policy_name }}</div>
          <div class="tips">
            <span>企业关注数</span>
            <div class="num">
              <img
                v-show="item.is_rise === 1"
                src="~assets/images/home/up_icon.png"
                alt=""
              />
              <div v-if="item.is_rise" class="numbers">{{ item.pv_count }}</div>
              <div v-else class="numbers" style="color: #c8a260">
                {{ item.pv_count }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import policiesTitle from '@/common/policiesTitle'

export default {
  components: { policiesTitle },
  props: {
    hotList: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    toDetail (pid) {
      this.$router.push({ path: '/detail', query: { pid } })
    }
  }
}
</script>
<style lang='scss' scoped>
#hotList {
  margin-top: 43px;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 21px;
    .left_part {
      margin-right: 10px;
      margin-top: 2px;
      .icon {
        width: 15px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        background: #ddd;
        border-radius: 2px;
      }
    }
    .right_part {
      flex: 1;
      display: flex;
      flex-direction: column;
      .part_title {
        max-width: 253px;
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tips {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
      .num {
        display: flex;
        align-items: center;
        img {
          width: 6px;
          height: 10px;
          margin-right: 6px;
        }
        .numbers {
          font-size: 14px;
          font-weight: bold;
          color: #f95355;
        }
      }
    }
  }
  li:last-of-type {
    margin-bottom: 0;
  }
  .first {
    background: #f95355 !important;
  }
  .second {
    background: #ff9f26 !important;
  }
  .third {
    background: #ffc11a !important;
  }
}
li:hover .part_title {
  color: #C8A260!important;
}
</style>
